<template>
  <span
    class="dropdown-container"
    v-on="{ ...$listeners }"
    v-click-outside="closeDropdown"
  >
    <span @click="toggle">
      <slot name="button">
        <hcc-button>{{ buttonName }}</hcc-button></slot
      >
    </span>
    <transition :name="getAnimationName()">
      <ul
        v-if="showList"
        class="dropdown"
        :class="[customClass, position, { custom }]"
        @click="toggle"
        v-click-outside="toggle"
        style="animation-duration: 0.5s"
      >
        <div class="dropdown__title" v-if="hasTitle">{{ title }}</div>
        <slot />
      </ul>
    </transition>
  </span>
</template>
<script>
import vClickOutside from 'v-click-outside';
import HccButton from '@/components/shared/HccButton/index.vue';

export default {
  components: {
    HccButton,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      showList: false,
    };
  },
  props: {
    buttonName: {
      type: String,
      default: 'Dropdown',
    },
    customClass: {
      type: [String, Object, Array],
    },
    position: {
      type: String,
      default: 'bottom-right',
    },
    custom: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
  },
  computed: {
    hasTitle() {
      return this.title.length > 0;
    },
  },
  methods: {
    toggle() {
      this.showList = !this.showList;
    },
    closeDropdown() {
      this.showList = false;
    },
    getAnimationName() {
      let name = '';

      if (this.position === 'bottom-left' || this.position === 'bottom' || this.position === 'bottom-right') {
        name = 'fadeDown';
      } else if (this.position === 'top-left' || this.position === 'top' || this.position === 'top-right') {
        name = 'fadeUp';
      } else if (this.position === 'right') {
        name = 'fadeRight';
      } else {
        name = 'fadeLeft';
      }

      return name;
    },
  },
};
</script>

<style scoped lang="scss">
@import "~styles/shared/dropdown.scss";
</style>
